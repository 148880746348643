class Wizard {
    constructor() {
        this.init();
    }

    setPage(pageId) {
        this.wizardPages.forEach((page) => {
            page.classList.remove("is-active");
        });
        const page = document.querySelector(`[data-wizard-page="${pageId}"]`);
        page.classList.add("is-active");
        window.location.hash = pageId;
        this.currentPage = parseInt(pageId);
        window.scrollTo(0, 0);
        this.wizardSteps.forEach((step) => {
            if (step.dataset.wizardStep <= this.currentPage) {
                step.classList.add("is-active");
            } else {
                step.classList.remove("is-active");
            }
        });
    }

    nextPage(e) {
        this.setPage(this.currentPage + 1);
        e.preventDefault();
    }

    init() {
        this.wizardSteps = document.querySelectorAll("[data-wizard-step]");
        this.wizardPages = document.querySelectorAll("[data-wizard-page]");
        this.wizardNext = document.querySelectorAll("[data-wizard-next]");

        if (this.wizardPages.length === 0) {
            // Return early if we don't have any wizards on this page
            return;
        }

        this.wizardPages[0]
            .closest("form")
            .querySelectorAll("input,textarea")
            .forEach((el) => {
                el.addEventListener("invalid", () => {
                    let invalidPage =
                        el.closest("[data-wizard-page]").dataset.wizardPage;
                    this.setPage(invalidPage);
                });
            });

        this.currentPage = null;

        this.wizardPages.forEach((page) => {
            page.classList.add("is-enabled");
        });
        this.wizardSteps.forEach((step) => {
            step.addEventListener("click", (e) =>
                this.setPage(e.target.dataset.wizardStep)
            );
        });
        this.wizardNext.forEach((next) => {
            next.removeEventListener("click", this.nextPage);
            next.addEventListener("click", this.nextPage.bind(this));
        });
        if (window.location.hash) {
            this.setPage(window.location.hash.split("#")[1]);
        } else {
            this.setPage(1);
        }
    }
}

export default Wizard;
