class ConditionalVisible {
    constructor() {
        this.changeListenersEls = [];
        this.init();
    }

    wrap(el) {
        if (!el.parentNode.dataset.hideWrapper) {
            const wrapper = document.createElement("span");
            wrapper.dataset.hideWrapper = "true";
            el.parentNode.insertBefore(wrapper, el);
            wrapper.appendChild(el);
        }
    }

    unwrap(el) {
        if (el.parentNode.dataset.hideWrapper) {
            let fragment = document.createDocumentFragment();
            let parent = el.parentNode;
            let child = parent.removeChild(parent.firstChild);
            fragment.appendChild(child);

            parent.parentNode.replaceChild(fragment, parent);
        }
    }

    addChangeListener(el, cb) {
        el.addEventListener("change", cb);
    }

    init() {
        const conditional = document.querySelectorAll("[data-visible-if]");

        const comparer = (element, targetValue) => {
            if (targetValue == "checked") {
                return element.checked;
            } else if (targetValue == "!checked") {
                return !element.checked;
            }
            if (element.type === "radio") {
                if (targetValue.startsWith("!")) {
                    return (
                        element.value !== targetValue.substr(1) &&
                        !element.checked
                    );
                } else {
                    return element.value === targetValue && element.checked;
                }
            }
            if (targetValue.startsWith("label:")) {
                const remainingValue = targetValue.substr(6);
                const label = element.options[element.selectedIndex].innerHTML;
                if (remainingValue.startsWith("!")) {
                    return label !== remainingValue.substr(1);
                } else {
                    return label === remainingValue;
                }
            }
            if (targetValue.startsWith("!")) {
                return element.value !== targetValue.substr(1);
            } else {
                return element.value === targetValue;
            }
        };

        conditional.forEach((el) => {
            // Dumb logic parsing
            const logic = el.dataset.visibleIf;
            const [field, targetValue] = logic.split("=");
            let matchingElements = document.querySelectorAll(`[name=${field}]`);
            matchingElements.forEach((matchingEl) => {
                this.addChangeListener(matchingEl, () => {
                    if (comparer(matchingEl, targetValue)) {
                        el.classList.remove("mod-hidden");
                        if (el.tagName == "OPTION") {
                            this.unwrap(el);
                        }
                    } else {
                        el.classList.add("mod-hidden");
                        if (el.tagName == "OPTION") {
                            this.wrap(el);
                        }
                    }
                    if (el.tagName == "OPTION") {
                        var event = new Event("change");
                        el.parentNode.dispatchEvent(event);
                    }
                });
                var event = new Event("change");
                matchingEl.dispatchEvent(event);
            });
            if (matchingElements.length == 0) {
                if (comparer(el, targetValue)) {
                    el.classList.remove("mod-hidden");
                } else {
                    el.classList.add("mod-hidden");
                }
            }
        });
    }
}

export default ConditionalVisible;
