import { library, dom, config } from "@fortawesome/fontawesome-svg-core";

import {
    faCalendarDays,
    faClock,
    faCheckCircle,
    faTimesCircle as faTimesCircleRegular,
    faUserCircle as faUserCircleRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {
    faMapMarkerAlt,
    faUserCircle,
    faShoppingBasket,
    faSearch,
    faFilter,
    faChevronDown,
    faChevronLeft,
    faTimes,
    faTimesCircle,
    faBell,
    faSignOutAlt,
    faBed,
    faLaptop,
    faDice,
    faDiceOne,
    faDiceTwo,
    faDiceThree,
    faDiceFour,
    faDiceFive,
    faDiceSix,
    faDiceD20,
    faUserFriends,
    faMobileAlt,
    faCube,
    faChair,
    faPlug,
    faRandom,
    faAward,
    faLock,
    faEdit,
    faTrash,
    faEnvelope,
    faExternalLinkAlt,
    faMap,
    faCertificate,
    faWallet,
    faGlobe,
    faVideo,
    faList,
    faBullhorn,
    faQrcode,
    faPlus,
    faMinus,
    faPerson,
    faCaretUp,
    faTicket,
    faChartGantt,
    faCalendarLinesPen,
    faCircleInfo,
    faMasksTheater,
    faBooks,
    faHandHoldingBox,
    faStore,
    faCoffeePot,
    faBlockQuestion,
    faSpinner,
    faRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faTwitch,
    faDiscord,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faFacebookF,
    faTwitter,
    faTwitch,
    faDiscord,
    faYoutube,
    faInstagram,
    faCalendarDays,
    faMapMarkerAlt,
    faUserCircle,
    faUserCircleRegular,
    faShoppingBasket,
    faSearch,
    faFilter,
    faChevronDown,
    faChevronLeft,
    faTimes,
    faTimesCircle,
    faTimesCircleRegular,
    faBell,
    faSignOutAlt,
    faBed,
    faClock,
    faLaptop,
    faDice,
    faDiceOne,
    faDiceTwo,
    faDiceThree,
    faDiceFour,
    faDiceFive,
    faDiceSix,
    faDiceD20,
    faUserFriends,
    faMobileAlt,
    faCheckCircle,
    faCube,
    faChair,
    faPlug,
    faRandom,
    faAward,
    faLock,
    faEdit,
    faTrash,
    faEnvelope,
    faExternalLinkAlt,
    faMap,
    faCertificate,
    faWallet,
    faGlobe,
    faVideo,
    faList,
    faCheckCircle,
    faBullhorn,
    faQrcode,
    faPlus,
    faMinus,
    faCaretUp,
    faTicket,
    faChartGantt,
    faCalendarLinesPen,
    faCircleInfo,
    faMasksTheater,
    faBooks,
    faHandHoldingBox,
    faStore,
    faCoffeePot,
    faBlockQuestion,
    faPerson,
    faSpinner,
    faRotateLeft
);

//config.searchPseudoElements = true;

export const refresh = () => {
    dom.watch();
};
