class EventSubmissionTools {
    constructor() {
        this.prepareUkgeLibraryPrefill();
    }

    prepareUkgeLibraryPrefill() {
        const gameSystemField = document.querySelector("[name='system']");
        if (!gameSystemField) {
            return;
        }

        gameSystemField.addEventListener("change", (e) => {
            const gameSystem = gameSystemField.value;
            if (gameSystem === "UKGE RPG Library") {
                const shortDescriptionField = document.querySelector(
                    "[name='short_description']"
                );
                const fullDescriptionEditor = document
                    .querySelector("[name=full_description]")
                    .parentElement.querySelector(
                        ".ck-editor__editable"
                    ).ckeditorInstance;

                if (shortDescriptionField.value === "") {
                    shortDescriptionField.value =
                        "A game selected from the UKGE RPG Library";
                }
                if (fullDescriptionEditor.getData() === "") {
                    fullDescriptionEditor.model.change((writer) => {
                        const insertPosition =
                            fullDescriptionEditor.model.document.selection.getFirstPosition();

                        fullDescriptionEditor.model.insertContent(
                            writer.createText(
                                "A game to be selected from the UKGE RPG library"
                            ),
                            insertPosition
                        );
                    });
                }
            }
        });
    }
}

export default EventSubmissionTools;
